import { FC, useEffect, useRef, useState } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    VacationCard,
    CongratulatedCard,
    FavoritesCard, StatusCard, MobileVacationCard,
} from "../../components/layout-components";
import {
    DepartmentBlock,
    ServicesBlock,
    PersonalWorkBlock,
    SportBlock,
} from "../../components/profile";
import { ArrowExitIcon, ArrowRightIcon, HeartIcon, PlusIcon2, ThreePeopleIcon } from "../../img";
import MainLayoutContent from "../../layouts/MainLayoutContent";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { BookIcon } from "../../img/icons/BookIcon";

export const ProfilePage: FC = () => {
    const isAdmin = true
    const isMobile = isMd()
    const navigate = useNavigate();

    const personalData = useSelector(getPersonData);

    const scrollableRef: any = useRef(null);

    const [ leaderItem, setLeaderItem ] = useState<any>();

    const [ departmentItems, setDepartmentItems ] = useState<any[]>([
        {
            id: 1,
            image: "/testImages/people/Image-1.png",
            name: "Краюшкин Александр Витальевич",
            position: "Руководитель",
            icon: "/testImages/statuses/statuses-1.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 2,
            image: "/testImages/people/Image-2.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 3,
            image: "/testImages/people/Image-3.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-3.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 4,
            image: "/testImages/people/Image-4.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-1.png",
            department: 'Департамент испытания и сертификации'
        },
        {
            id: 5,
            image: "/testImages/people/Image-5.png",
            name: "Смирнова Виалета Михайловна",
            position: "HR менеджер",
            icon: "/testImages/statuses/statuses-2.png",
            department: 'Департамент испытания и сертификации'
        },
    ]);

    const [sportData, setSportData] = useState([
        {
            id: 1,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
        {
            id: 2,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: false,
        },
        {
            id: 3,
            weekDay: 'Вторник',
            month: 'Июля',
            day: 13,
            type: 'Тренировка',
            text: 'Бег на дорожке',
            startTime: '17:30',
            endTime: '19:00',
            isMuted: true,
        },
    ])

    const changeDepartmentData = (item: any) => {
        return {
            ...item,
            image: item?.user?.photo,
            name: item?.user?.name,
            position: item?.title,
            icon: "",
            department: ""
        }
    }

    useEffect(() => {
        if (personalData) {
            const data = {
                "id": 1684,
                "ws_id": "1620",
                "title": "Отдел6",
                "department_id": "6",
                "parent_id": "5",
                "direct_head": 4,
                "actual": 1,
                "is_active": 1,
                "sort": null,
                "city_id": "675000",
                "city_name": "Тимтаун",
                "laravel_through_key": "21",
                "team": {
                    "leader": {
                        "id": 14018,
                        "ws_id": "1694",
                        "inner_id": "13",
                        "department_id": "6",
                        "department_head": 1,
                        "title": "Руководитель дивизиона Центральный",
                        "is_head": null,
                        "is_active": 1,
                        "unused_vacation": null,
                        "near_vacation_start": null,
                        "near_vacation_end": null,
                        "user": null
                    },
                    "staff": [
                        {
                            "id": 13577,
                            "ws_id": "1620",
                            "inner_id": "21",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": {
                                "id": 1,
                                "inner_id": "21",
                                "workspace_id": 1,
                                "mail": null,
                                "firstname": "Aki",
                                "lastname": "Developer",
                                "middle_name": "",
                                "name": "Developer Aki ",
                                "photo": "",
                                "sex": 1,
                                "workspace_role": {
                                    "workspace_id": 1,
                                    "user_id": 1,
                                    "privacy": 1,
                                    "privacy_mail": 0,
                                    "privacy_birth": 0,
                                    "privacy_messenger": 0
                                }
                            }
                        },
                        {
                            "id": 14080,
                            "ws_id": "1866",
                            "inner_id": "21",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": {
                                "id": 1,
                                "inner_id": "21",
                                "workspace_id": 1,
                                "mail": null,
                                "firstname": "Aki",
                                "lastname": "Developer",
                                "middle_name": "",
                                "name": "Developer Aki ",
                                "photo": "",
                                "sex": 1,
                                "workspace_role": {
                                    "workspace_id": 1,
                                    "user_id": 1,
                                    "privacy": 1,
                                    "privacy_mail": 0,
                                    "privacy_birth": 0,
                                    "privacy_messenger": 0
                                }
                            }
                        },
                        {
                            "id": 16594,
                            "ws_id": "1936",
                            "inner_id": "128915",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16598,
                            "ws_id": "1936",
                            "inner_id": "121313",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16605,
                            "ws_id": "1936",
                            "inner_id": "163630",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16608,
                            "ws_id": "1936",
                            "inner_id": "166641",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16592,
                            "ws_id": "1936",
                            "inner_id": "134657",
                            "department_id": "6",
                            "department_head": 2,
                            "title": "Ведущий технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16593,
                            "ws_id": "1936",
                            "inner_id": "134966",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16597,
                            "ws_id": "1936",
                            "inner_id": "127345",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16600,
                            "ws_id": "1936",
                            "inner_id": "118620",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16603,
                            "ws_id": "1936",
                            "inner_id": "158950",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Ведущий технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16604,
                            "ws_id": "1936",
                            "inner_id": "156849",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Главный специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16607,
                            "ws_id": "1936",
                            "inner_id": "149552",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Главный специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16596,
                            "ws_id": "1936",
                            "inner_id": "126631",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Главный технический специалист",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 10408,
                            "ws_id": "1620",
                            "inner_id": "15",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 10426,
                            "ws_id": "1620",
                            "inner_id": "16",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 14090,
                            "ws_id": "1866",
                            "inner_id": "15",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 14091,
                            "ws_id": "1866",
                            "inner_id": "16",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Мерчендайзер",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": null,
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16595,
                            "ws_id": "1936",
                            "inner_id": "126069",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Руководитель дирекции",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16599,
                            "ws_id": "1936",
                            "inner_id": "118486",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Руководитель направления",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16601,
                            "ws_id": "1936",
                            "inner_id": "113056",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Руководитель направления",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16606,
                            "ws_id": "1936",
                            "inner_id": "165202",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Специалист технической поддержки",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        },
                        {
                            "id": 16602,
                            "ws_id": "1936",
                            "inner_id": "55139357",
                            "department_id": "6",
                            "department_head": 0,
                            "title": "Эксперт",
                            "is_head": null,
                            "is_active": 1,
                            "unused_vacation": "0",
                            "near_vacation_start": null,
                            "near_vacation_end": null,
                            "user": null
                        }
                    ]
                }
            }

            setLeaderItem(changeDepartmentData(data?.team?.leader))
            setDepartmentItems(data.team?.staff.map(item => changeDepartmentData(item)))
        }
    }, [personalData]);

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };

    return (
        <div className='profile'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Моя страница</h1>
                    </div>
                ) }
            </div>

            <MainLayoutContent
                contentGap={ 40 }
                leftComponents={
                    <>
                        <ProfileCard />
                        <StatsCard />
                        <StatusCard />

                        <div onClick={() => navigate("/profile")}>
                            <div className="layout-adaptation">
                                <div className="layout-adaptation__left">
                                    <BookIcon />

                                    <h3>Адаптация</h3>
                                </div>
                            </div>
                        </div>

                        <BadgesCard />
                        <CongratulatedCard />

                        <div onClick={() => navigate("/favorites")}>
                            <FavoritesCard />
                        </div>

                        {isMobile ? <MobileVacationCard data={departmentItems} /> :
                            <VacationCard data={departmentItems} />}
                    </>
                }
            >
                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моё подразделение</h1>

                        <div className='layout-right-block__header__link'>
                            <h4>Показать все</h4>
                            <ArrowRightIcon color={ "#000" } />
                        </div>
                    </div>

                    <div className="profile-departments__content">
                        {leaderItem && <DepartmentBlock { ...leaderItem } />}
                        { departmentItems.slice(0, 4).map((
                            item,
                            idx
                        ) => (
                            <DepartmentBlock key={ idx } { ...item } />
                        )) }
                    </div>
                </div>

                <div className="layout-right-block">
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Моя работа</h1>
                    </div>

                    <PersonalWorkBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '>Мои сервисы</h1>

                        <div className='layout-right-block__header__right'>
                            {/*{isAdmin && !isMobile && (*/}
                            {/*    <button onClick={() => navigate('/profile/colleagues')} className='layout-right-block__header__addButton profile-services-addButton'>*/}
                            {/*        <ThreePeopleIcon />*/}

                            {/*        <p>Мои сотрудники</p>*/}

                            {/*        <span>*/}
                            {/*            3*/}
                            {/*        </span>*/}
                            {/*    </button>*/}
                            {/*)}*/}

                            <button className='layout-right-block__header__addButton profile-services-addButton'>
                                <PlusIcon2 />

                                <p>Подать новую</p>
                            </button>
                        </div>
                    </div>

                    <ServicesBlock />
                </div>

                <div className='layout-right-block'>
                    <div className='layout-right-block__header'>
                        <h1 className='layout-right-block__header__title '> Спорт </h1>

                        <button className='layout-right-block__header__addButton'>
                            <PlusIcon2 />

                            <p>Записаться</p>
                        </button>
                    </div>

                    <div className='profile-sport'>
                        {sportData.map((item, idx) => (
                            <SportBlock key={idx} {...item}/>
                        ))}
                    </div>
                </div>
            </MainLayoutContent>
        </div>

    );
};