import React, { FC, useState } from "react";
import { ProfileFileIcon, ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfileData } from "../../redux";
import Button from "../ui/form/Buttons/Button";

interface IProfileCard {
    data?: any
}
export const ProfileCard: FC<IProfileCard> = ({
  data
}) => {
    const profileData = useSelector(getProfileData)

    const navigate = useNavigate();
    const location = useLocation()
    const [ openPopup, setOpenPopup ] = useState(false);

    const handlePopup = () => setOpenPopup(!openPopup);

    return (
        <>
            <div className='layout-profile'>
                <div className='layout-profile__avaBox'  /*  onClick={() => push('/profile-settings')} */
                     style={ {cursor: 'pointer'} }>
                    {profileData.photo ? (
                      <img src={ profileData?.photo } className='layout-profile__avatar'
                           style={ {marginRight: '0'} } />
                    ) : (
                      <div className='layout-profile__avatar__noneImage'>
                          {profileData?.name && profileData?.name[0]}
                      </div>
                    )}

                </div>

                <div className='layout-profile__nameBlock'>
                    <h1>{profileData?.lastname} {profileData?.firstname} {profileData?.middle_name}</h1>

                    <div>
                        <ProfileFileIcon />

                        <p>{profileData?.workspace_title_position}</p>
                    </div>
                </div>


                { location.pathname === '/profile' ? (
                    <div className='layout-profile__buttons'>
                        <button className='layout-profile__buttons__qrCodeButton'>
                            <ProfileQrCodeSmallIcon color='#00A7B5'/>
                            <p>  Моя визитка</p>
                        </button>

                        <Button
                            text={'Редактировать'}
                            className={"btn btn-light"}
                            onClick={() => navigate('edit')}
                        />
                    </div>
                ) : (
                    <button className='layout-profile__businessCard' type='button'
                            onClick={ () => navigate('profile') }>
                        Моя страница
                    </button>
                ) }
            </div>
        </>
    )
}
