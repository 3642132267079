import { FC } from "react";
import { ProfileChatIcon, ProfileEnvelopeIcon } from "../../img";

interface IProfileDepartmentBlock {
    id?: number,
    name: string;
    image: string;
    position: string;
    icon: string;
    isMessage?:boolean;
    department?: string;
}
export const DepartmentBlock: FC<IProfileDepartmentBlock> = ({
    name,
    image,
    position,
    icon,
    isMessage = false,
}) => {
    return (
        <div className='profile-departments__block'>
            <div className='profile-departments__images'>
                <img className='profile-departments__image' src={image} alt="" />

                {icon && <img className="profile-departments__icon" src={icon} alt="" />}
            </div>

            <h3 className='profile-departments__title'>{name}</h3>

            <p className='profile-departments__position'>{position}</p>

            {isMessage && (
                <div className='profile-departments__icons'>
                    <ProfileEnvelopeIcon/>

                    <ProfileChatIcon/>
                </div>
            )}
        </div>
    )
}