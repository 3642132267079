import { FC, ReactNode } from "react";
import Button from "../Buttons/Button";

interface ToggleListProps {
    list: any[];
    title?: string;
    className?: string;
    id?: string;
    errorMessage?: string;
    onChange: (value: any) => void;
    value: string;
}

const ToggleList: FC<ToggleListProps> = (
    {
        title,
        className = "",
        id,
        errorMessage,
        list,
        onChange,
        value,
    }
) => {

    return (
        <div className={"form-group toggle__list"}>
            {title && (
                <label
                    htmlFor={id}
                    className={"form-label"}
                >
                    {title}
                </label>
            )}

            <div className={`toggle__list__items ${className}`}>
                {list.map((item: any, idx: number) => (
                    <Button
                        key={`toggle-item-btn-${id}-${idx}`}
                        id={id}
                        text={item.text}
                        className={`btn ${item.text === value ? "btn-primary" : "btn-primary-revert"}`}
                        disabled={item.disabled}
                        onClick={() => onChange(item)}
                    />
                ))}
            </div>

            {errorMessage && (
                <p className={"bank-card-field__number-block__error"}>
                    {errorMessage || ""}
                </p>
            )}
        </div>
    );
};

export default ToggleList;
