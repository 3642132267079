import { ArrowExitIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { $getBlogs } from "../../../api/requests/blogs";
import { BlogsDraftCard } from "../../../components/blogs";
import { isMd } from "../../../utils";

export const BlogsDraftsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [data, setData] = useState<IBlogsRes[]>([])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    };


    useEffect(() => {
        $getBlogs().then(res => {
            if (!res.data) return;

            setData(res.data)
        })
    }, [])

    return (
        <div className='blogs-drafts'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                ) }
            </div>

            <div className='blogs-drafts-content'>
                {data.map((draft, idx) => (
                    <div  onClick={ () => navigate(`/blogs/show?blogs_id=${ draft.id }`) } key={idx}>
                        <BlogsDraftCard data={draft}/>
                    </div>
                ))}
            </div>
        </div>
    )
}