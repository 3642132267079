import { ReactNode } from "react";
import { DraftIcon, NotificationIcon, PenIcon, PlusIcon2, SearchIcon } from "../img";
import { store } from "../redux";

interface IRouteLinks {
    route: string,
    title: string
}
export const routeLinks: Record<string, IRouteLinks> = {
    main: {
        route: '/',
        title: 'Главная',
    },

    user: {
        route: '/user',
        title: 'Профиль',
    },

    workspace_settings: {
        route: '/workspace-settings',
        title: 'Настройки организации'
    },

    blogs: {
       route: '/blogs',
       title: 'Блог',
    },
    blogs_drafts: {
        route: '/blogs/drafts',
        title: 'Черновик',
    },
    blogs_show: {
        route: '/blogs/show',
        title: ''
    },
    blogs_create: {
        route: '/blogs/create',
        title: 'Создание'
    },
    blogs_edit: {
        route: '/blogs/edit',
        title: 'Редактирование'
    },

    services: {
        route: '/services',
        title: 'Сервисы',
    },
    services_create: {
        route: '/services/create',
        title: 'Создание'
    },
    services_education: {
        route: '/services/education',
        title: 'Заявка на обучение'
    },

    profile: {
        route: '/profile',
        title: 'Моя страница'
    },
    profile_colleagues: {
        route: '/profile/colleagues',
        title: 'Мои сотрудники'
    },

    favorites: {
        route: '/favorites',
        title: 'Избранное'
    },
    favorites_employees: {
        route: '/favorites/employees',
        title: 'Сотрудники'
    },
    favorites_news: {
        route: '/favorites/news',
        title: 'Новости'
    },
    favorites_polls: {
        route: '/favorites/polls',
        title: 'Опросы'
    },
    favorites_competitions: {
        route: '/favorites/competitions',
        title: 'Конкурсы'
    },

    competitions: {
        route: '/competitions',
        title: 'Конкурсы'
    },
    competitionsView: {
        route: '/competitions/view',
        title: 'Просмотр'
    },
    competitionsCreate: {
        route: '/competitions/create',
        title: 'Создание'
    },

    news: {
        route: '/news',
        title: 'Новости'
    },

    news_show: {
        route: `/news/show`,
        title: ''
    },

    polls: {
        route: '/polls',
        title: 'Опросы'
    },
    polls_create: {
        route: '/polls/create',
        title: 'Создание'
    },
    polls_edit: {
        route: '/polls/edit',
        title: ''
    },

    birthdays: {
        route: '/birthdays',
        title: 'Дни рождения'
    },

    employees: {
        route: '/employees',
        title: 'Новые сотрудники',
    },

    calendar: {
        route: '/calendar',
        title: 'Календарь',
    },

    vacation: {
        route: '/calendar/vacation',
        title: 'Отпуска'
    },

    medical: {
        route: '/calendar/medical',
        title: 'Больничный'
    },

    business: {
        route: '/calendar/business',
        title: 'Командировки'
    },
}

export const updateRouteTitle = (newTitle: any) => {
    return routeLinks.news_edit.title = newTitle
};

type IconConfigType = (navigate: any, params?: any, ) => {
    [key: string]: {
        leftIcons: { icon: ReactNode; onClick: () => void }[];
        rightIcons: { icon: ReactNode; onClick: () => void }[];
    };
};
export const iconConfig: IconConfigType = (navigate, params) => ({
    "/": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"} />,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
    "/competitions": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/competitions/create"),
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
    "/blogs": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/blogs/create"),
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
    "/blogs/create": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/blogs/drafts"),
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
    "/blogs/drafts": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/blogs/create"),
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
    "/blogs/show": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/blogs/edit?${params}`)
                },
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },

    "/polls": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/polls/drafts"),
            },
            {
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/polls/create"),
            },
            {
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
    },
});