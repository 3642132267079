import { ArrowExitIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { NewsCard, NewsSlider } from "../../components/news";
import { useEffect, useState } from "react";
import { $getFavoritesNews, $getNewsList } from "../../api/requests/news";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { SearchItem } from "../../components/ui/form/SearchItem";
import { isMd } from "../../utils";

export const NewsPage = () => {
    const navigate = useNavigate()
    const isMobile = isMd();
    const [newsData, setNewsData] = useState<any[]>([])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getFavoritesNews().then(res => {
            if (!res.data) return;

            setNewsData(res.data)
        })
    }, [])

    return (
        <div className='news'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Новости</h1>
                    </div>
                ) }

                <div className='news-search'>
                    <SearchItem params={[]} onChange={() => ''}/>
                </div>
            </div>

            <NewsSlider data={newsData}/>

            <div className='news-content'>
                <div className='news-content__header'>
                    <Dropdown
                        className='workspace-select'
                        placeholder={'Категория'}
                        options={[]}
                        onChange={() => ''}
                    />
                </div>

                <div className='news-content__items'>
                    {newsData.map((item, idx) => (
                        <div key={idx} onClick={() => navigate(`/news/show?news_id=${item.id}`)}>
                            <NewsCard data={item}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}