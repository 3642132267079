import { changeProfile, changeProfileVarsData } from "../../redux";
import { $profileData } from "../../api/requests/auth";
import { Dispatch } from "@reduxjs/toolkit";

export const profileData = (dispatch: Dispatch) => {
    const defaultColorScheme = {
        primaryColor: "#003166", //blueColor
        secondaryColor: "#007AFF", //lightBlue
        thirdColor: "#E9F0F9", //grayColor
        fourthColor: "#FFF" //whiteColor
    };

    function getFuncProfileData() {
        $profileData().then((res) => {
            if (!res) return console.error('error profile')

            const groupAdminModule =
                res?.group_admin_module?.filter(Boolean).length > 0;

            const isOwner =
                (+res?.workspace_role === 1 ||
                    +res?.group_role === 1 ||
                    +res?.group_role === 2) &&
                !groupAdminModule;

            dispatch(
                changeProfileVarsData({
                    newMain: !!+res?.main_page_new,
                    newProfile: !!+res?.profile_new,
                    colors: res.workspace_design
                        ? JSON.parse(res.workspace_design)
                        : defaultColorScheme,
                    isOwner
                })
            );

            dispatch(changeProfile(res));
        });
    }

    getFuncProfileData();
}