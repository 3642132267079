import { IApiHandlerParams } from "../../interface";
import { SportResponseData } from "../sport/interface";
import api from "../../index";
import { WorkspaceEditData } from "../main/interface";

export const $getWorkspaceUsers = (
  data: any,
  handler?: IApiHandlerParams
): Promise<any> =>
  api.post('api/workspaces/users', data, {
    // mock: require('./mocks/complete.form.calculate.json'),
    handler,
  })