import { userInitialStateType } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from "../../store"

export const initialStateProfileReducer: userInitialStateType = {
    data: null,
    vars: {},
    person: null
}

const profileReducer = createSlice({
    name: 'profile',
    initialState: initialStateProfileReducer,
    reducers: {
        resetProfile: (state) => {
            state.data = null
        },
        changeProfile: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        changeProfileVarsData: (state, action: PayloadAction<any>) => {
            state.vars = { ...state.vars, ...action.payload }
        },
        changePersonData: (state, action: PayloadAction<any>) => {
            state.person = action.payload
        },
    },
})

export const {
    resetProfile,
    changeProfile,
    changeProfileVarsData,
    changePersonData
} =
    profileReducer.actions

export const getProfileData = (state: RootState): any => state.profile.data

export const getProfileVarsData = (state: RootState): any => state.profile.vars

export const getPersonData = (state: RootState): any => state.profile.person

export default profileReducer.reducer
