import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $getUser = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`user/user_page/${id}`, {
        handler
    });

export const $getPersonalPage = (
    data?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("api/v1/user/user_page", {
        request: {
            params: data,
        },
        handler
    });