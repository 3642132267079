import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignInLayout from "../layouts/SignInLayout";
import { useSelector } from "react-redux";
import { getProfileData } from "../redux";
import Auth from "../containers/auth";
import Activate from "../containers/auth/activate";
import ProtectedRoute from "../components/ui/ProtectedRoute";
import NotFound from "../components/ui/NotFound";
import Register from "../containers/auth/register";
import {
    MainPage,
    ProfilePage,
    WorkspacePage,
    CalendarPage,
    ReferencePage,
    ServicesPage,
    Contacts,
    VacationPage,
    BirthdaysPage,
    EmployeesPage,
    ProfileColleagues,
    FavoritesPage,
    FavoritesEmployeesPage,
    FavoritesNewsPage,
    FavoritesPolls,
    FavoritesCompetitions,
    NewsPage,
    NewsShowPage,
    PollsPage,
    BlogsPage,
    BlogsDraftsPage,
    BlogsShowPage,
    PollsCreatePage, BlogsCreatePage, ServicesCreatePage, ServicesEducationPage, UserProfilePage, WorkspaceSettingsPage
} from "../containers";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import InnerLayout from "../layouts/InnerLayout";
import ContestsPage from "../containers/contests/ContestsPage";
import ContestsCreateEditPage from "../containers/contests/ContestsCreateEdit";
import ContestsPageApplications from "../containers/contests/ContestsPageApplications";
import StructurePage from "../containers/services/structure/StructurePage";
import TrainingPage from "../containers/sport/TrainingPage";
import TrainingSessionViewPage from "../containers/sport/view/TrainingSessionViewPage";

const RoutesComponent = () => {
    const pathname = window.location.pathname
    const userData = useSelector(getProfileData);
    const [ isScrolled, setIsScrolled ] = useState(false)

    const redirectTo = (url: string): string => {
        if (window.location.pathname !== url) window.location.href = url;

        return url;
    };

    const isRouterMain = (): string => {
        if (!userData) return "/auth";

        if (userData?.role === 9) return redirectTo("/guest");

        return userData?.centerId === null ? "/non/game" : "/";
    };

    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setIsScrolled(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pathname]);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, [pathname]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={<SignInLayout />}
                >
                    <Route path="/auth" element={<Auth />} />

                    <Route path="/verification" element={<Activate />} />
                    <Route path="/register" element={<Register />} />
                </Route>

                <Route
                    element={<MainLayout isScrolled={isScrolled}/>}
                >
                    <Route path="/" element={<ProtectedRoute />}>
                        {/*<Route index element={<Navigate to={isRouterMain()} replace/>}/>*/}

                        <Route index element={<MainPage isScrolled={isScrolled} />} />

                        <Route path='workspace' element={<WorkspacePage/>}/>

                        <Route path='calendar'>
                            <Route path='' element={<CalendarPage/>}/>
                        </Route>

                        <Route path='reference' element={<ReferencePage/>}/>

                        <Route path='contacts' element={<Contacts/>}/>

                        <Route path='services' element={<ServicesPage/>}/>

                        <Route path='blogs' element={<BlogsPage/>}/>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout />}>
                    <Route path='calendar'>
                        <Route path='vacation' element={<VacationPage/>}/>
                        <Route path='medical' element={<VacationPage/>}/>
                        <Route path='business' element={<VacationPage/>}/>
                    </Route>

                    <Route path='employees' element={ <EmployeesPage /> } />

                    <Route path="profile" >
                        <Route path='' element={<ProfilePage/>}/>
                        <Route path='colleagues' element={<ProfileColleagues/>}/>
                    </Route>

                    <Route path='birthdays' element={<BirthdaysPage/>}></Route>

                    <Route path='favorites' >
                        <Route path='' element={<FavoritesPage/>}/>
                        <Route path='employees' element={<FavoritesEmployeesPage/>}/>
                        <Route path='news' element={<FavoritesNewsPage/>}/>
                        <Route path='polls' element={<FavoritesPolls/>}/>
                        <Route path='competitions' element={<FavoritesCompetitions/>}/>
                    </Route>

                    <Route path='news'>
                        <Route path='' element={<NewsPage/>}/>
                        <Route path="show" element={ <NewsShowPage/> }/>
                    </Route>

                    <Route path='polls' >
                        <Route path='' element={<PollsPage/>}/>
                        <Route path='edit' element={<PollsCreatePage/>}/>
                        <Route path='create' element={<PollsCreatePage/>}/>
                    </Route>

                    <Route path='competitions'>
                        <Route path='' element={<ContestsPage/>}/>
                        <Route path='application' element={<ContestsPageApplications/>}/>
                        <Route path='create' element={<ContestsCreateEditPage/>}/>
                        <Route path='edit' element={<ContestsCreateEditPage/>}/>
                    </Route>

                    <Route path='blogs'>
                        <Route path='drafts' element={<BlogsDraftsPage/>}/>
                        <Route path='show' element={<BlogsShowPage/>}/>
                        <Route path='create' element={<BlogsCreatePage/>}/>
                        <Route path='edit' element={<BlogsCreatePage/>}/>
                    </Route>

                    <Route path='services'>
                        <Route path='create' element={<ServicesCreatePage/>}/>
                        <Route path='structure' element={<StructurePage/>}/>
                        <Route path='education' element={<ServicesEducationPage/>}/>
                    </Route>

                    <Route path='sport'>
                        <Route path='view/:id' element={<TrainingSessionViewPage/>} />
                    </Route>

                    <Route path='workspace-settings'>
                        <Route path='' element={<WorkspaceSettingsPage/>}/>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout isShowBreadcrumb={false}/>}>
                    <Route path='user/:id'>
                        <Route path='' element={<UserProfilePage/>} />
                    </Route>
                </Route>


                <Route path={"/"} element={<InnerLayout isShowBreadcrumb={false}/>}>
                    <Route path='sport'>
                        <Route path='' element={<TrainingPage/>} />
                    </Route>
                </Route>

                <Route
                    path={"*"}
                    element={<NotFound />}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
