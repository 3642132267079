import React, { FC, useState } from 'react'
import { Link, useLocation } from "react-router-dom";
import { isMd } from "../../utils";
import {
    SliderContactsIcon,
    SliderFeedIcon,
    SliderProfileIcon, SliderReferenceIcon, SliderServicesIcon,
    SliderWorkplaceIcon,
} from "../../img";

interface FooterHomeProps {
    openMenu: () => void
    onClickMenuFooter: (i: any) => void
    listMenu: any[]
    isOpenMenu?: boolean
}

const FooterHome: FC<FooterHomeProps> = ({
    openMenu,
    listMenu,
    onClickMenuFooter,
    isOpenMenu,
}) => {
    const location = useLocation();
    const isMobile: boolean = isMd()

    const [ mobileFooterRouters, setMobileFooterRouters ] = useState([
        {
            id: 0,
            title: 'Лента',
            route: '/',
            icon: <SliderFeedIcon />
        },
        {
            id: 6,
            title: 'Cервисы',
            route: '/services',
            icon: <SliderServicesIcon />
        },
        {
            id: 1,
            title: 'Места',
            route: '/workspace',
            icon: <SliderWorkplaceIcon />
        },


        {
            id: 4,
            title: 'Визитки',
            route: '/contacts',
            icon: <SliderContactsIcon />
        },

        {
            id: 2,
            title: 'Страница',
            route: '/profile',
            icon: <SliderProfileIcon />
        },

        // {
        //     id: 3,
        //     title: 'Справки',
        //     route: '/reference',
        //     icon: <SliderReferenceIcon />
        // },
        //
        // {
        //     id: 3,
        //     title: 'Отпуска',
        //     route: '/vacation',
        //     icon: <SliderWorkspaceIcon />
        // },
    ])

    const isNoHome: string[] = ['home']

    return (
        <>
            {!isMobile && (
                <footer className={'footer'}>
                    <div className={'container'}>
                        <div className={'footer__item'}>
                            <Link to={'/'} className={'footer__logo'}>
                                <img
                                    width={280}
                                    height={40}
                                    src="/images/logo.png"
                                    alt="logo"
                                    // layout={'responsive'}
                                />
                            </Link>

                            <div className={'footer__social'}>
                                <Link
                                    to={'/'}
                                    className={'footer__social-block'}
                                >
                                    <img
                                        width={35}
                                        height={35}
                                        src="/images/icons/social/vk.svg"
                                        alt="vk"
                                        // layout={'responsive'}
                                    />
                                </Link>

                                <Link
                                    to={'/'}
                                    className={'footer__social-block'}
                                >
                                    <img
                                        width={35}
                                        height={35}
                                        src="/images/icons/social/fb.svg"
                                        alt="fb"
                                        // layout={'responsive'}
                                    />
                                </Link>

                                <Link
                                    to={'/'}
                                    className={'footer__social-block'}
                                >
                                    <img
                                        width={35}
                                        height={35}
                                        src="/images/icons/social/ok.svg"
                                        alt="ok"
                                        // layout={'responsive'}
                                    />
                                </Link>

                                <Link
                                    to={'/'}
                                    className={'footer__social-block'}
                                >
                                    <img
                                        width={35}
                                        height={35}
                                        src="/images/icons/social/tw.svg"
                                        alt="tw"
                                        // layout={'responsive'}
                                    />
                                </Link>

                                <Link
                                    to={'/'}
                                    className={'footer__social-block'}
                                >
                                    <img
                                        width={35}
                                        height={35}
                                        src="/images/icons/social/ig.svg"
                                        alt="ig"
                                        // layout={'responsive'}
                                    />
                                </Link>
                            </div>

                            <div className={'footer__mobile'}>
                                <div className={'footer__mobile-block'}>
                                    <img
                                        src="/images/qr.png"
                                        alt="play-market"
                                        // layout={'responsive'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            )}

            {isMobile && (
                <div className={'menu__footer'}>
                    <div className="menu__footer__block">
                        <div className="menu__footer__items">
                            {mobileFooterRouters.map((item: any, idx: number) =>
                                item.key === 'home' ? (
                                    ''
                                ) : (
                                    <Link
                                        to={''}
                                        key={
                                            'list-footer-menu__footer-item-' +
                                            idx
                                        }
                                        className={`menu__footer__item ${
                                            isNoHome.includes(item.key)
                                                ? 'd-none'
                                                : ''
                                        }`}
                                    >
                                        <button
                                            onClick={() =>
                                                onClickMenuFooter(item)
                                            }
                                            className={`menu__footer__item ${location.pathname === item.route ? 'active' : ''}`}
                                        >
                                            <div className="menu__footer__item__icon">
                                                {item.icon}
                                            </div>

                                            <div className="menu__footer__item__text">
                                                <p>{item.title}</p>
                                            </div>
                                        </button>
                                    </Link>
                                )
                            )}
                        </div>
                    </div>

                    <div
                        className={`spinner__wrapper ${
                            isOpenMenu ? 'active' : ''
                        }`}
                        onClick={openMenu}
                    ></div>
                </div>
            )}
        </>
    )
}

export default FooterHome
