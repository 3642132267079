import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";

import ContestCreateEditPreview from "./ContestCreateEditPreview";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import TextField from "../../../components/ui/form/Fields/TextField";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon, CoinCompetitionIcon } from "../../../img";
import { isMd } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

export interface CreateContestFormData {
    image: File | string | null;
    name: string;
    desc: string;
    date_start: string;
    date_end: string;
    category: any;
    points_win: string;
    points_participation: string;
    clip: boolean;
    views?: number;
    peoples?: number;
    status?: string;
}

const ContestsCreateEditPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    const contest_id = searchParams.get("contest_id");
    const isDraft = searchParams.get("is_draft");

    const isEdit: boolean = pathname.includes("edit");

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);
    const [editData, setEditData] = useState<CreateContestFormData | null>(null);

    const [isShow, setIsShow] = useState(true);

    const testBtnCreate = isDraft ? "Опубликовать" : (isEdit ? "Сохранить" : "Создать");

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid },
        setValue
    } = useForm<CreateContestFormData>(
        (isEdit && editData) ? {
            values: {
                image: editData.image,
                name: editData.name,
                desc: editData.desc,
                date_start: editData.date_start,
                date_end: editData.date_end,
                category: editData.category,
                points_win: editData.points_win,
                points_participation: editData.points_participation,
                clip: editData.clip
            }
        } : {}
    );

    const handleNavigation = () => {
        const pathArray = location.pathname.split("/");
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join("/") || "/";
            navigate(newPath);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        if (isEdit && contest_id) {
            // $getContestById(contest_id).then(res => {
            //     if (!res.data) return;
            //
            //     // setEditData(res);
            setEditData({
                image: "",
                name: "title",
                desc: "desc",
                date_start: "",
                date_end: "",
                category: "",
                points_win: "23",
                points_participation: "32",
                clip: true
            });
            // });
        }
    }

    function onSubmit(data: CreateContestFormData) {
        const requestData = {
            ...data,
            date_start: moment(data.date_start).format("DD.MM.yyyy"),
            date_end: moment(data.date_end).format("DD.MM.yyyy"),
            category: data.category.value,
            points_win: data.points_win,
            points_participation: data.points_participation,
            clip: data.clip || false
        };

        if (isEdit) return handleEditData(requestData);

        // $uploadImage(data.image).then(res => {
        //     $createContest(requestData).then(res => {
        //         if (!res.data) return;
        //
        //         reset();
        //         navigate("/contest");
        //     })
        // })
    }

    function handleEditData(requestData: CreateContestFormData) {
        if (requestData.image !== editData?.image) {
            // $uploadImage(requestData.image).then(res => {
            //     $editContest(contest_id, requestData).then(response => {
            //         if (!response.data) return;
            //
            //         reset();
            //         navigate("/contest");
            //     })
            // })

            return;
        }

        // $editContest(contest_id, requestData).then(res => {
        //     if (!res.data) return;
        //
        //     reset();
        //     navigate("/contest");
        // })
    }

    function handleDelete() {
        // $deleteContest(contest_id).then(res => {
        //     if (!res.data) return;
        //
        //     reset();
        //     navigate("/contest");
        // })
    }

    function handleSaveDraft() {
        const requestData = {
            image: watch().image,
            name: watch().name,
            desc: watch().desc,
            date_start: watch().date_start,
            date_end: watch().date_end,
            category: watch().category,
            points_win: watch().points_win,
            points_participation: watch().points_participation,
            clip: watch().clip
        };

        if (requestData.image) {
            // $uploadImage(requestData.image).then(res => {
            //     $createContest(requestData).then(res => {
            //         if (!res.data) return;
            //
            //         reset();
            //         navigate("/contest");
            //     })
            // })

            return;
        }

        // $createContest(requestData).then(res => {
        //     if (!res.data) return;
        //
        //     reset();
        //     navigate("/contest");
        // })
    }

    return (
        <div className="contests__create">
            <div className="layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? editData.name : "Создать конкурс"}</h1>

                        {isDraft && <h5>Черновик</h5>}
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="contests__create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Прикрепите картинку"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ImageField
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите название"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Название конкурса"
                                            placeholder={"Введите название"}
                                            name="name"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="desc"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Описание"
                                            placeholder={"Опишите конкурс"}
                                            name="name"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <div className={"contests__create__form-date"}>
                                    <Controller
                                        name="date_start"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={value}
                                                className=""
                                                label="Дата начала"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="date_end"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={value}
                                                className=""
                                                label="Дата завершения"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />
                                </div>

                                <Controller
                                    name="category"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете категорию"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Категория не выбрана"}
                                            label={"Категория"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="points_win"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите баллы"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            type={"number"}
                                            title="Баллы за победу"
                                            placeholder={"Введите баллы"}
                                            name="points_win"
                                            onChange={onChange}
                                            prepend={<CoinCompetitionIcon />}
                                        />
                                    )}
                                />

                                <Controller
                                    name="points_participation"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите баллы"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            type={"number"}
                                            title="Баллы за участие"
                                            placeholder={"Введите баллы"}
                                            name="points_participation"
                                            onChange={onChange}
                                            prepend={<CoinCompetitionIcon />}
                                        />
                                    )}
                                />

                                <Controller
                                    name="clip"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="contests__create__form-toggle"
                                            desc="Закрепить конкурс"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={handleDelete}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                        onClick={handleSaveDraft}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <ContestCreateEditPreview
                                        data={watch()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContestsCreateEditPage;