import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage, changePersonData,
    getMainPageData, getPersonData,
    getProfileData
} from "../redux";
import Cookies from "js-cookie";
import { $mainPageNewData } from "../api/requests/main";
import Preloader from "../components/ui/preloader/Preloader";
import HeaderHome from "../components/header/HeaderHome";
import AsideHome from "../components/header/AsideHome";
import FooterHome from "../components/header/FooterHome";
import { Navigate, Outlet } from "react-router-dom";
import ScrollToTop from "../utils/scroll/ScrollToTop";
import { Slider } from "../components/slider/Slider";
import { profileData } from "../utils/loadData/profileData";
import { useVskAccess } from "../hooks";
import { $getPersonalPage } from "../api/requests/user";

interface IMainLayout {
    isScrolled: boolean
}
const MainLayout: FC<IMainLayout> = ({isScrolled}) => {
    const mainData = useSelector(getMainPageData);
    const localProfileData = useSelector(getProfileData);
    const localPersonData = useSelector(getPersonData);
    const dispatch = useDispatch();

    const [scroll, setScroll] = useState<any>(false);

    const listMenu: any[] = [
        {
            img: "/images/icons/home.svg",
            text: "Главная страница",
            key: "home",
            count: 0,
            active: true,
            link: "/"
        },
        // {
        //     img: "/images/icons/facebook_like.svg",
        //     text: "Благодарности",
        //     key: "urls",
        //     count: 2,
        //     active: false,
        //     backColorCount: "#FFD600",
        //     colorCount: "#000000",
        //     link: "/"
        // },
        {
            img: "/images/icons/news.svg",
            text: "Новости",
            key: "news",
            count: 5,
            active: false,
            backColorCount: "#C7EF71",
            colorCount: "#000000",
            link: "/news"
        },
        {
            img: "/images/icons/megaphone.svg",
            text: "Конкурсы",
            key: "news",
            active: false,
            count: 0,
            link: "/competitions"
        },
        {
            img: "/images/icons/check_all.svg",
            text: "Опросы",
            key: "news",
            active: false,
            count: 0,
            link: "/polls"
        },
        {
            img: "/images/icons/user.svg",
            text: "Новые сотрудники",
            key: "news",
            active: false,
            count: 0,
            link: "/employees"
        },
        {
            img: "/images/icons/user.svg",
            text: "Структура компании",
            key: "news",
            active: false,
            count: 0,
            link: "/services/structure"
        },
    ];

    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const [personalData, setPersonalData] = useState([]);

    const { jsonData } = useVskAccess({ data: personalData });

    if (!Cookies.get("token")) {
        return <Navigate to={"/auth"} replace />;
    }

    useEffect(() => {
        !localProfileData && profileData(dispatch);

        !localPersonData && getFuncPersonData();
    }, []);

    useEffect(() => {
        if (mainData) return;

        $mainPageNewData().then((res) => {
            if (!res || res.error) return;

            dispatch(changeMainPage(res));
        });
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setScroll(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, []);

    useEffect(() => {
        jsonData && dispatch(changePersonData(jsonData))
    }, [jsonData]);

    function getFuncPersonData() {
        $getPersonalPage(null,{ newApi: true }).then(res => {
            if (!res) return console.error('error person data');

            setPersonalData(res);
        })
    }

    function openMenu(): void {
        setIsOpenMenu(!isOpenMenu);
    }

    function onClickMenuFooter(i: any): void {
        if (i.key === "navigation") openMenu();
    }

    if (!localProfileData || !mainData)
        return (
            <div
                className={"w-100"}
                style={{
                    height: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Preloader size={"large"} />
            </div>
        );

    return (
        <>
            <HeaderHome openMenu={openMenu} />

            <Slider isScrolled={scroll} />

            <AsideHome
                isActive={isOpenMenu}
                openMenu={openMenu}
                menu={listMenu}
            />

            <div className={`main ${isScrolled ? 'main-scrolled' : ''}`}><Outlet /></div>

            <FooterHome
                isOpenMenu={isOpenMenu}
                openMenu={openMenu}
                listMenu={listMenu}
                onClickMenuFooter={onClickMenuFooter}
            />

            <ScrollToTop />
        </>
    );
};

export default MainLayout;
