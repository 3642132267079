import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Cookies from "js-cookie";

import { $authSign } from "../../api/requests/auth";
import { phoneNumberFieldMask, successNotify } from "../../utils";
import { transformData } from "../../api";
import { changeProfileVarsData } from "../../redux";

import Button from "../ui/form/Buttons/Button";
import TextField from "../ui/form/Fields/TextField";
import MaskTextField from "../ui/form/Fields/MaskTextField";

type Inputs = {
    phone: string
    password: string
}

export default function LoginForm() {
	const navigate = useNavigate()
    const dispatch = useDispatch()

    const [errorMessage, setErrorMessage] = useState<string>('')
    const [loginMail, setLoginMail] = useState<string>('')
    const [passwordMail, setPasswordMail] = useState<string>('')
    const [isEye, setIsEye] = useState<boolean>(false)

    const changeTypePassword = (type: boolean): void => {
        setIsEye(!type)
    }

    async function onSubmit(inputs: Inputs) {
        setErrorMessage('')

        const formData = transformData(inputs)

        await $authSign({ phone: inputs.phone, password: inputs.password}, {formData: true}).then((res) => {
            if (res && res?.token) {
                successNotify('Вы успешно авторизованы')

                Cookies.set('token', res.token)
                dispatch(
                    changeProfileVarsData({
                        token: res.token
                    })
                )
				navigate('/')
            }
        })

        // const res = await signIn('credentials', {
        //     phone: formData.get('phone'),
        //     password: formData.get('password'),
        //     redirect: false,
        // })
        //
        // if (res && !res.error) {
        //     // successNotify('Вы успешно авторизованы')
        //
        //     router.push('/')
        // } else {
        //     console.log(res, 'error auth')
        // }
    }

    return (
        <form
            onSubmit={(event) => {
                event.preventDefault()

                onSubmit({ phone: loginMail, password: passwordMail })
            }}
            className="auth__login__form"
        >
            <div>
                <div className="form-block">
                    <MaskTextField
                        value={loginMail}
                        onChange={setLoginMail}
                        label={'Телефон'}
                        mask={phoneNumberFieldMask}
                        errorMessage={errorMessage}
                        id={"login"}
                        autoFocus
                    />

                    <TextField
                        type={isEye ? 'text' : 'password'}
                        value={passwordMail}
                        onChange={setPasswordMail}
                        append={
                            <>
                                <Button
                                    className="btn btn-link"
                                    text={
                                        <img
                                            src={'/images/icons/question.svg'}
                                            width="21"
                                        />
                                    }
                                />

                                <Button
                                    className="btn btn-link"
                                    text={
                                        <img
                                            src={
                                                isEye
                                                    ? '/images/icons/eye.svg'
                                                    : '/images/icons/hide-eye.svg'
                                            }
                                            width="24"
                                        />
                                    }
                                    onClick={() => changeTypePassword(isEye)}
                                />
                            </>
                        }
                        label={'Пароль'}
                        id={"password"}
                        errorMessage={errorMessage}
                    />
                </div>

                <div className="auth__start show">
                    <Button
                        className="btn btn-primary"
                        type={'submit'}
                        text={'Войти'}
                    />

                    <Link to={'/register'} className="btn btn-gray">
                        Зарегистрироваться
                    </Link>
                </div>
            </div>
        </form>
    )
}
