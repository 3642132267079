import { useWindowSize } from "../../hooks";

export function isMd(): boolean {
    const [width] = useWindowSize();

    return width < 1020
}

export function isSm(): boolean {
    const [width] = useWindowSize();

    return width > 560 && width < 1020
}

export function isLg(): boolean {
    const [width] = useWindowSize();

    return width < 1441 && width > 1020
}