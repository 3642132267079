import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCheckCrypt } from '../hooks'
import { decryptFields } from '../utils'
import { getProfileData } from '../redux'

const parseJson = (data: any) => {
    const dataObj = { jsonData: data }

    if (!data) return dataObj

    try {
        if (typeof data === 'string') {
            return { jsonData: JSON.parse(data.replace(/\s+/g, ' ').trim()) }
        }
        return { jsonData: JSON.parse(data) }
    } catch (err) {
        return dataObj
    }
}

export const useVskAccess = ({
    data,
    customClass = '',
    inlineStyle = {},
    isSettings = false,
    isStopPropagation = true,
}: any) => {
    const profileData = useSelector(getProfileData)
    const { vskModal, handleOpenVsk } = useCheckCrypt(false, profileData)
    const [isDecryptError, setDecryptError] = useState(false)

    const defaultClass = customClass || 'block-vsk mobile-block-vsk'

    const AccessContainer = useCallback(
        ({ children, onClick, IsNoBlur }: any) => (
            <>
                {vskModal}
                <div
                    className={`sidebar-user-container ${defaultClass} ${isDecryptError && !IsNoBlur ? 'blur-access-vsk' : ''} ${
                        isSettings ? 'settings-vsk' : ''
                    }`}
                    onClick={
                        onClick ||
                        ((e) => {
                            if (isStopPropagation) {
                                e.stopPropagation()
                            }
                            if (IsNoBlur && !profileData?.id) return

                            if (isDecryptError) handleOpenVsk()
                        })
                    }
                    style={{
                        cursor: profileData?.id && isDecryptError ? 'pointer' : 'auto',
                        ...inlineStyle,
                    }}
                >
                    {children}
                </div>
            </>
        ),
        [isDecryptError, vskModal, data]
    )

    const baseData = { AccessContainer, jsonData: data || {} }
    const localKey = localStorage.getItem('secret') || ''
    const cryptWsId: string = process.env["NEXT_PUBLIC_CRYPT_WS_ID"] || ''
    const isSecureWs = profileData?.wsId === +cryptWsId

    // decrypt only for secure workspace
    if (!isSecureWs) return baseData

    const decryptedData =
        data && decryptFields(data, { isDecryptError, setDecryptError })

    if (isDecryptError) return { ...baseData, isDecryptError: true }
    // decrypt only for test users
    if (!profileData?.id) {
        return { ...baseData }
    }

    if (!localKey) {
        setDecryptError(true)
        return { ...baseData, isDecryptError: true }
    }

    const { jsonData } = parseJson(decryptedData)

    return { AccessContainer, jsonData: jsonData || {}, isDecryptError }
}
