import { forwardRef, useEffect, useRef, useState } from "react";
import { FieldJSXProps } from "./types";
import { useCalcFieldSlotSize } from "./hooks/useCalcFieldSlotSize";

type Ref = HTMLInputElement;

const TextField = forwardRef<Ref, FieldJSXProps>(
    (
        {
            className = "",
            id = "",
            label,
            title,
            prepend,
            append,
            placeholder,
            value,
            onChange,
            autoFocus,
            maxLength,
            pattern,
            inputMode,
            errorMessage,
            type,
            isSingle,
            required,
            ...props
        },
        ref
    ) => {
        const prependElRef = useRef<any>(null);
        const appendElRef = useRef<any>(null);
        const [paddingLeft, setPaddingLeft] = useState<number>(14 / 16);
        const [paddingRight, setPaddingRight] = useState<number>(14 / 16);

        useCalcFieldSlotSize(prependElRef, setPaddingLeft, prepend);
        useCalcFieldSlotSize(appendElRef, setPaddingRight, append);

        return (
            <div className={"form-group"}>
                {title && (
                    <label
                        htmlFor={id}
                        className={"form-label"}
                    >
                        {title}
                    </label>
                )}

                <div
                    className={`field ${className} ${!!(value || "").trim() ? "active" : ""}`}
                    {...props}
                >
                    {prepend && (
                        <div
                            ref={prependElRef}
                            className="field__prepend"
                        >
                            {prepend}
                        </div>
                    )}

                    <input
                        ref={ref}
                        value={value}
                        onChange={(e) => onChange && onChange(e.target.value)}
                        type={type || "text"}
                        id={id}
                        placeholder={placeholder}
                        style={{
                            paddingLeft: paddingLeft + 0.1 + "rem",
                            // width: `calc(100% - ${paddingRight}rem)`,
                            paddingTop: label ? "1.75rem" : "0.625rem"
                        }}
                        className="form-control"
                        autoFocus={autoFocus}
                        autoComplete={"off"}
                        name={id}
                        maxLength={maxLength}
                        pattern={pattern}
                        inputMode={inputMode}
                        required={required}
                    />

                    <div
                        className="field__holder"
                        style={
                            isSingle
                                ? {
                                    borderRadius: "0.625rem"
                                }
                                : {}
                        }
                    >
                        {(label) && (
                            <div className="field__content">
                                <div className="field__label-wrapper">
                                    <label
                                        className="field__label"
                                        htmlFor={id}
                                    >
                                        {label}
                                    </label>
                                </div>
                            </div>
                        )}
                    </div>

                    {append && (
                        <div ref={appendElRef} className="field__append">
                            {append}
                        </div>
                    )}
                </div>

                {errorMessage && (
                    <p className={"bank-card-field__number-block__error"}>
                        {errorMessage || ""}
                    </p>
                )}
            </div>
        );
    }
);

TextField.displayName = "TextField";

export default TextField;
