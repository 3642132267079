import { FC, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { CommentIcon, EyeIcon, LikeIcon, NewsArrowIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";

interface INews {
    category_id: number,
    category_title: string,
    comments: string,
    comments_count: number,
    custom_likes_count: number,
    custom_users_like: number,
    date: string,
    group_id: number,
    id: number,
    image: string,
    likeImage: string,
    like_image_file: string,
    like_name: string,
    likes_count: number,
    order: number,
    status: number,
    title: string,
    views: number,
    users_like: number,
    isLiked: boolean,
}
interface INewsSlider {
    data: INews[]
}
export const NewsSlider: FC<INewsSlider> = ({
    data
}) => {
    const navigate = useNavigate();
    const isMobile = isMd()

    return (
        <div className='news-slider'>
            <div className='news-slider__leftIcon'>
                <NewsArrowIcon/>
            </div>

            <div className='news-slider__content'>
                <Swiper
                    id={'slider'}
                    slidesPerView={'auto'}
                    speed={1000}
                    spaceBetween={0}
                    centeredSlides={true}
                    loop={true}
                    autoplay={false}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        prevEl: ".news-slider__leftIcon",
                        nextEl: ".news-slider__rightIcon",
                    }}
                    className='mySwiper'
                >
                    {data.map((slide, index) => (
                        <SwiperSlide
                            onClick={() => navigate(`/news/show?news_id=${slide.id}`)}
                            key={index}
                            style={{backgroundImage: `url(${slide.image})`}} className={`${index === 0 ? 'swiper-slider-active' : ''}`}>
                            <div className='news-slider__content__linear'></div>

                            <div className='news-slider__content__desc'>
                                <h1 className='news-slider__content__desc__title'>{slide.title}</h1>

                                <div className='news-slider__content__desc__category'>
                                    {slide.category_title}
                                </div>

                                <div className='news-slider__content__desc__bottom'>
                                    <div className='news-slider__content__desc__dateBox'>
                                        <NewsDateIcon size={ '1.25rem' } fill={'#fff'} />
                                        <div>{ slide.date }</div>
                                    </div>

                                    <div className='news-slider__content__desc__stats'>
                                        <div className='news-slider__content__desc__stats__block'>
                                            <EyeIcon color={'#fff'}/>

                                            <h1>{ slide.views }</h1>
                                        </div>

                                        <div className='news-slider__content__desc__stats__block'>
                                            <CommentIcon color='#fff'/>

                                            <h1>{ slide.comments_count }</h1>
                                        </div>

                                        <div className='news-slider__content__desc__stats__block'>
                                            <LikeIcon color='#fff' />

                                            <h1>{ slide.likes_count }</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className='news-slider__rightIcon'>
                <NewsArrowIcon/>
            </div>
        </div>
    )
}