import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { SportItemResponseData, SportResponseData } from "./interface";

export const $getSportList = (
    handler?: IApiHandlerParams
): Promise<SportResponseData> => api
    .get( `api/v1/sport`, {
        mock: require('./mocks/sport.list.json'),
        handler,
    })
export const $getSportById = (
    id: string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
    .get( `api/v1/sport/${id}`, {
        mock: require('./mocks/sport.item.json'),
        handler,
    })