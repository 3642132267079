import { FC, useState, useEffect, } from "react";
import {
    BadgesCard,
    ProfileCard,
    StatsCard,
    EmployeesCard,
    ContestCard, BirthdaysCard, BannerCard, LinksCard
} from "../../components/layout-components";
import {
    FeedGroupsContent,
    FeedEventsContent,
} from "../../components/main";
import { ArrowRightIcon, HeartIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import { NewsCard, NewsCardProps } from "../../components/news";
import { ILinks } from "../../utils";
import { useSelector } from "react-redux";
import { getMainPageData } from "../../redux";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

interface IMainPage {
    isScrolled: boolean
}

export const MainPage: FC<IMainPage> = ({isScrolled}) => {
    const navigate = useNavigate()
    const isMobile = window.screen.width <= 1020
    const mainData = useSelector(getMainPageData);

    const [newsData, setNewsData] = useState([])
    const [linksData, setLinksData] = useState<ILinks[]>([])

    const [ banners, setBanners ] = useState([
        {
            id: 2,
            image: "testImages/banners/banner-1.png",
            src: "testImages/banners/banner-2.png",
            title: 'современные электронные системами',
            icon: 'testImages/banners/banner-icon.png',
            layout: "left",
            type: "2"
        },
        {
            id: 1,
            image: "testImages/banners/banner-2.png",
            src: "testImages/banners/banner-1.png",
            title: 'передовые электронные системами',
            icon: 'testImages/banners/banner-icon.png',
            layout: "left",
            type: "1"
        }
    ]);

    const [ employeesContent, setEmployeesContent ] = useState<IEmployeesContent[]>([
        {
            id: 1,
            name: 'Воробьёв Илья Романович',
            text: 'Департамент испытания и сертификации',
            position: 'Руководитель отдела',
            image: '/testImages/people/Image-1.png',
            isLiked: true,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 2,
            name: 'Кузьмин Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-2.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 3,
            name: 'Белов Дмитрий Константинович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-3.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 4,
            name: 'Воробьёв Евгений Петрович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-4.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        },
        {
            id: 5,
            name: 'Сергеев Борис Антонович',
            text: 'Департамент испытания и сертификации',
            position: 'Менеджер по продажам',
            image: '/testImages/people/Image-5.png',
            isLiked: false,
            birth: '15 мая, 2024',
            number: '+7 925 353-01-10',
            email: 'ivanov@itelma.ru',
            skills: [ 'Анализирует требования заказчика', 'Разрабатывает тесты', 'Коммуникация с проектной командой' ],
            leader: {
                image: '/testImages/people/Image-2.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            },
            replacer: {
                image: '/testImages/people/Image-3.png',
                name: 'Иванов Иван Иванович',
                number: '+7 925 353-01-10',
                email: 'ivanov@itelma.ru',
            }
        }
    ])

    useEffect(() => {
        if (mainData) {
            setLinksData(mainData.links)
            setNewsData(mainData.news)
        }

    }, [mainData])

    const handleLike = (id: number) => {
        const updatedList: any = employeesContent.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                }
            } else {
                return item
            }
        })

        setEmployeesContent(updatedList)
    };

    return (
      <div className='feed'>
          <div className='feed-left'>
                  { isMobile ? (
                    <>
                        <StatsCard />

                        <BadgesCard />

                        <BirthdaysCard />

                    </>
                  ) : (
                    <>
                        <ProfileCard/>
                        <StatsCard/>
                        <BadgesCard />
                        <BirthdaysCard />
                        <EmployeesCard />
                        <BannerCard />
                        <ContestCard />
                        <LinksCard data={linksData}/>
                    </>
                  ) }
          </div>

          <div className='feed-right'>
              {isMobile ? (
                <div>
                    <div className='feed-controls feed-news__controls' >
                        {/* <div className={allViewsClass}>{allViews}</div> */}
                        <h3>Новые сотрудники</h3>

                        <div onClick={() => navigate('employees')}>
                            <h4>Показать все</h4>
                            <ArrowRightIcon color={'#000'}/>
                        </div>
                    </div>

                    <div
                      style={{ gridTemplateColumns: `repeat(${employeesContent.length}, minmax(230px, 1fr))` }}
                      className='feed-groups__container'>
                        { employeesContent.map((
                          item,
                          idx
                        ) => (
                          <div className='employees-block' key={ idx } >
                              <div className='employees-block__top'>
                                  <img className='employees-block__image' src={ item?.image } alt="" />

                                  <div
                                    onClick={ () => {
                                        handleLike(item.id)
                                    } }
                                    className={ `employees-block__likeIcon ${ item.isLiked ? 'active' : '' }` }
                                  >
                                      <HeartIcon />
                                  </div>
                              </div>

                              <div className='employees-block__content' >
                                  <h1 className='employees-block__title'>{ item?.name }</h1>

                                  <p className='employees-block__text'>{ item?.text }</p>

                                  <p className='employees-block__position'>{ item?.position }</p>
                              </div>
                          </div>
                        )) }
                    </div>
                </div>
              ): null}

              <FeedGroupsContent />

              <div className="feed-banners">
                  { banners.map((
                    banner,
                    idx
                  ) => (
                    <div key={ `banner-item-${ idx }` } className="feed-banners__block">
                        <img className='feed-banners__block__icon' src={ banner?.icon } alt="icon" />
                        <img className='feed-banners__block__image' src={ banner?.image } alt="banner" />

                        <h1>
                            { banner?.title }
                        </h1>
                    </div>
                  )) }
              </div>

              <div className='feed-news'>
                  <div className='feed-controls feed-news__controls' >
                      {/* <div className={allViewsClass}>{allViews}</div> */}
                      <h3>Новости</h3>

                      <div onClick={() => navigate('news')}>
                          <h4>Больше новостей</h4>
                          <ArrowRightIcon color={'#000'}/>
                      </div>
                  </div>

                  <div className='feed-news__content'>
                      {newsData?.slice(0, 6).map((item: NewsCardProps, idx: number) => (
                        <NewsCard data={item} key={idx}/>
                      ))}
                  </div>
              </div>

              {isMobile && <ContestCard/>}

              {isMobile && <LinksCard data={linksData}/>}

              {isMobile && <div style={{padding: '0 1rem'}}><BannerCard /></div>}

              <FeedEventsContent />
          </div>
      </div>
    );
};