import { useEffect, useState } from "react";
import { $getWorkspaceUsers } from "../../api/requests/workspace-settings";
import { IWorkspaceUsers, IWorkspaceUsersNames } from "../../api/requests/workspace-settings/interface";
import { SettingIcon, StarIcon } from "../../img";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import InfiniteScroll from "../ui/InfiniteScroll";
import { getProfileData } from "../../redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";
import { SingleSkeleton } from "../ui/skeleton/Single";
import { CardSkeleton } from "../ui/skeleton/CardSkeleton";
export const WorkspaceUsersBlock = () => {
  const navigate = useNavigate();
  const profileData = useSelector(getProfileData);

  let [increment, setIncrement] = useState(0);
  const [activeTab, setActiveTab] = useState(1);
  const [params, setParams] = useState<any>({
    id: profileData.workspace_id,
    forceToSend: increment,
  });


  const [items, setItems] = useState<any>({})
  const [usersData, setUsersData] = useState<IWorkspaceUsers[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(false)

  params.type = activeTab;

  useEffect(() => {
    setIsLoading(true)
    $getWorkspaceUsers(params, {formData: true}).then(res => {
      if (!res) return

      const withFilter = res?.users?.filter((item: any, i: number) => {
        const modules = res.users[i].admin_module;
        const isEditor = !!modules?.filter((el: any) => el).length;

        const tabToRole = () => {
          if (activeTab === 1) return item.role === 4;
          // if (activeTab === 1) return item.role === 4 && !isEditor;
          if (activeTab === 2) return item.role === 3;
          if (activeTab === 3) return item.role === 1 || item.role === 2;
          // if (activeTab === 3) return item.role === 1 || item.role === 2 || isEditor;
          return false;
        };
        return tabToRole();
      });

      setIsLoading(false)
      setItems({ ...res, users: withFilter });
      setUsersData(res.users)
    })
  }, [])

  const getNamesOfUser = (id: number, data: any) => {
    return data.find((item: any) => item.id == id)
  }

  return (
    // <InfiniteScroll
    //   path="/workspaces/users"
    //   setParams={ setParams }
    //   params={ {...params} }
    //   handleResult={ (res: any) => {
    //     setItems(!params.offset ? res : { ...items, users: [...items.users, ...res.users] });
    //   } }
    //   onError={ () => {
    //   } }
    //   saveRedux={ undefined }
    // >
    //   {items && (
    //
    //   )}
    // </InfiniteScroll>

  <div className='workspace-settings-users'>
    {isLoading && [1, 2, 3, 4].map((item, idx) => (
      <div className='workspace-settings-users__skeleton'>
        <CardSkeleton/>
      </div>
    ))}

    {!isLoading && usersData?.map((user, idx) => (
      <div className='workspace-settings-user' key={idx} onClick={() => navigate(`/user/${user.id}`)}>
        <div className='workspace-settings-user__header'>
          {user.photo ? (
            <img src={user?.photo} alt=""  className='workspace-settings-user__image'/>
          ) : (
            <div className='workspace-settings-user__image'></div>
          )}


          <StarIcon color='#00A7B5' strokeColor='#00A7B5'/>
        </div>

        <div className='workspace-settings-user__content'>
          <h1 className='workspace-settings-user__name'>{user?.firstname} {user?.middle_name} {user?.lastname}</h1>

          <p className='workspace-settings-user__text'>Департамент испытания и сертификации</p>

          <p className='workspace-settings-user__text workspace-settings-user__position'>{getNamesOfUser(128, user.names)?.value || '-'}</p>
        </div>

        <p className='workspace-settings-user__number'>+{user?.phone}</p>

        <div className='workspace-settings-user__line'></div>

        <div className='workspace-settings-user__bottom'>
          <div className='workspace-settings-user__points'>
            <ProfileBalanceIcon size={'1.5rem'}/>

            <p>{user?.user_points}</p>
          </div>

          <SettingIcon color='#00A7B5'/>
        </div>
      </div>
    ))}

  </div>
  )
}