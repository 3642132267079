import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateRouteTitle } from "../../../redux";
import { $getFavoritesNews } from "../../../api/requests/news";
import { ArrowExitIcon, CommentIcon, EyeIcon, LikeIcon, ShareIcon } from "../../../img";
import { NewsCard } from "../../../components/news";
import { ClockIcon } from "@mui/x-date-pickers";
import { CommentMessenger } from "../../../components/comment-messenger";
import { isMd } from "../../../utils";

export const NewsShowPage = () => {
    const isMobile = isMd();

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();

    const newsId = searchParams.get('news_id');

    const [ newsList, setNewsList ] = useState([])
    const [ newsData, setNewsData ] = useState<any>([])

    const handleNavigation = () => {
        const pathArray = location.pathname.split('/');
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join('/') || '/';
            navigate(newPath);
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        $getFavoritesNews().then(res => {
            if (!res.data) return;

            setNewsList(res.data)

            const getNews = res.data.find((item: any) => item.id == newsId)
            setNewsData(getNews)
        })
    }, [])

    useEffect(() => {
        dispatch(updateRouteTitle({key: 'news_show', title: newsData?.title}));
    }, [ newsData ])

    return (
        <div className='news-show'>
            <div className='layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>{ newsData?.title }</h1>
                    </div>
                ) }
            </div>

            <div className='news-show-content'>
                <div className='news-show-content__left'>
                    <div className='news-show-info'>
                        <div className='news-show-info__image'
                             style={ {backgroundImage: `url(${ newsData.image })`} } />

                        <div className='news-show-info__content'>
                            <div className='news-show-info__header'>
                                <div className='news-show-info__header__practice'>{newsData?.category_title}</div>

                                <div className='news-show-info__header__types'>
                                    <div className='news-show-info__header__types__item'>#Практика</div>
                                    <div className='news-show-info__header__types__item'>#Бизнес</div>
                                    <div className='news-show-info__header__types__item'>#Лотерея</div>
                                </div>
                            </div>

                            <div className='news-show-info__editor'>
                                <h5>Тульский филиал поддержал подключение компании-партнера ПАО «НПО «Стрела»к программе
                                    лояльности ВСК, устроив для сотрудников этой организации лотерею. Акция действовала
                                    в июле и августе текущего года, а результаты стали известны в октябре.
                                </h5>

                                <p>#Слайд с описанием условий подключения и самой лотереи был размещен на внутреннем
                                    портале предприятия. Сотрудникам необходимо было зарегистрироваться в нашем
                                    приложении, указав специальный код. Помимо возможности участвовать в розыгрыше
                                    призов, всем установившим приложение #ВСК открылся доступ к корпоративной программе
                                    лояльности для сотрудников предприятий-партнеров. С помощью приложения сотрудники
                                    теперь могут оформлять дистанционно любой вид страхования с хорошими скидками.
                                </p>

                                <p>
                                    С помощью генератора случайных чисел коллеги выбрали 3-х победителей из списка
                                    подключенных. Двое уже успели забрать свои призы – это теплый плед и термокружка.
                                    Исполнительный директор по дистанционному сервису Ангелина Потокина с теплом
                                    встретила победителей акции в офисе ВСК.
                                </p>
                            </div>

                            <div className='news-show-info__date'>
                                <ClockIcon/> <p>20 октября, 2020 12:44</p>
                            </div>

                            <div className='news-show-info__bottom'>
                                <div className='news-show-info__buttons'>
                                    <button className='news-show-info__buttons__likeButton'>
                                        <LikeIcon size={24}/> <p>Мне нравиться</p>
                                    </button>

                                    <button className='news-show-info__buttons__shareButton'>
                                        <ShareIcon size={24} color='#00A7B5'/> <p>Мне нравиться</p>
                                    </button>
                                </div>

                                <div className='news-show-info__stats'>
                                    <div className='news-show-info__stats__block'>
                                        <LikeIcon color='#000' size={20}/>

                                        <p>{newsData.likes_count}</p>
                                    </div>

                                    <div className='news-show-info__stats__block'>
                                        <CommentIcon color='#000' size={20}/>

                                        <p>{newsData.comments_count}</p>
                                    </div>

                                    <div className='news-show-info__stats__block'>
                                        <EyeIcon color='#000' size={20}/>

                                        <p>{newsData.views}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {newsData.comments_array ? (<CommentMessenger comments_data={newsData?.comments_array}/>) : null}
                </div>

                <div className='news-show-content__right'>
                    { newsList.slice(0, 4).map((item) => (
                        <NewsCard data={ item } />
                    )) }
                </div>
            </div>
        </div>
    )
}