import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";

export const BadgesCard = () => {
    const [badgesData, setBadgesList] = useState([]);

    const personalData = useSelector(getPersonData);

    const groupData = (): any => {
        return [{}, ...badgesData].reduce((acc: any, curr: any) => {
            const key = curr.badge_id;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        });
    };

    const filterData = badgesData?.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.badge_id === v.badge_id) === i);

    const checkBadgesSize = (badgeId: number) => groupData()[badgeId]?.length;

    useEffect(() => {
        if (personalData?.data) {
            personalData?.data.badges && setBadgesList(personalData?.data.badges?.personal)
        }
    }, [personalData]);

    function openBadgesListModal(item: any) {}

    return (
        <div className='layout-badges'>
            <div className="layout-congratulations__header">
                <h1>Благодарности</h1>

                <p>{filterData?.length}</p>
            </div>

            {filterData?.length ? (
                <div className='layout-badges__list'>
                    {filterData.slice(0, 4)?.map((item: any, idx: number) => {
                        const isBadgesSize = checkBadgesSize(item.badge_id);

                        return (
                            <div
                                key={idx}
                                className='layout-badges__item'
                                onClick={(e) => openBadgesListModal(item)}
                            >
                                <img src={item.image} />

                                {isBadgesSize > 1 &&
                                  <div className='layout-badges__itemCount'>{isBadgesSize}</div>}
                            </div>
                        );
                    })}
                </div>
            ) : <div className={"empty"}>Список пуст</div>}
        </div>
    )
}
