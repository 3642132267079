import { ArrowExitIcon, ArrowRightIcon, SettingIcon } from "../../img";
import { isMd } from "../../utils";
import { useNavigate } from "react-router-dom";
import { WorkspaceUsersBlock } from "../../components/workspace-users";
import { SearchItem } from "../../components/ui/form/SearchItem";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { useRef, useState } from "react";
import { Transition } from "react-transition-group";

interface ISettingsPageChild {
  key: string,
  title: string,
  parent: string,
}

interface ISettingsPage {
  key: string,
  title: string,
  isOpen?: boolean,
  child?: ISettingsPageChild[]
}

const duration = 300;

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0
}

const transitionStyles = {
  entering: { opacity: 1, height: "50%" },
  entered:  { opacity: 1, height: 'auto' },
  exiting:  { opacity: 0, height: "50%" },
  exited:  { opacity: 0, height: 0 },
};

export const WorkspaceSettingsPage = () => {
  const navigate = useNavigate();
  const isMobile = isMd();

  const nodeRef = useRef(null);

  const [activePage, setActivePage] = useState('navigation')
  const [activeChildPage, setActiveChildPage] = useState('')

  const [settingsPage, setSettingsPages] =useState<ISettingsPage[]>([
    {
      key: 'navigation',
      title: 'Навигация'
    },
    {
      key: 'banners',
      title: 'Банеры'
    },
    {
      key: 'users',
      title: 'Пользователи',
      isOpen: false,
      child: [
        {
          key: 'admins',
          title: 'Администраторы',
          parent: 'users',
        },
        {
          key: 'users',
          title: 'Пользователи',
          parent: 'users',
        },
        {
          key: 'trainer',
          title: 'Тренера',
          parent: 'users',
        }
      ]
    },
    {
      key: 'user_fields',
      title: 'Поля пользователя'
    },
    {
      key: 'market',
      title: 'Копр. Магазин'
    },
    {
      key: 'vacation',
      title: 'Отпуска'
    },
    {
      key: 'accrual',
      title: 'Начисления руководителям'
    }
  ])

  const handleOpenPage = (isChild: boolean, key: string) => {
    if (isChild) {
      const updatedList = settingsPage.map((item) => {
        return {
          ...item,
          isOpen: !item.isOpen
        }
      })

      setSettingsPages(updatedList)
    }
    else {
      const updatedList = settingsPage.map((item) => {
        return {
          ...item,
          isOpen: false
        }
      })

      setSettingsPages(updatedList)
      setActiveChildPage('')
      setActivePage(key)
    }
  }

  const handleOpenChildPage = (key: string, parent: string,) => {
    setActiveChildPage(key)
    setActivePage(parent)
  }

  return (
    <div className='workspace-settings'>
      <div className='layout-inner__header user-header'>
        { !isMobile && (
          <div className='layout-inner__header__title'>
            <div className='header__exitIcon' onClick={ () => navigate(-1) }>
              <ArrowExitIcon />
            </div>
            <h1>Настройки организации</h1>
          </div>
        ) }

        <div className='workspace-settings-users__header'>
          <div className='workspace-settings-users__header__dropdown'>
            <Dropdown options={[{value: 1, label: 'Роль'}]} onChange={() => ''}/>
          </div>

          <div className='workspace-settings-users__header__search'>
            <SearchItem params={[]} onChange={() => ''}/>
          </div>
        </div>
      </div>

      <div className='workspace-settings-content'>

        <div className='workspace-settings-left'>
          {settingsPage.map((item, idx) => (
              <div className={`workspace-settings-page`}
                   key={idx}
              >
                <div
                    className={`workspace-settings-page__main ${activePage === item.key ? "active" : ""}`}
                    onClick={() => handleOpenPage(!!item.child, item.key)}
                >
                  <div className="workspace-settings-page__left">
                    <SettingIcon color="#000" />

                    <p className="workspace-settings-page__title">{item.title}</p>
                  </div>

                  {item.child && (
                      <div className={`arrow-icon ${item.isOpen ? "active" : ""}`}>
                        <ArrowRightIcon />
                      </div>
                  )}
                </div>

                <Transition nodeRef={nodeRef} in={item.isOpen} timeout={500}>
                  {state => (
                      <div
                          ref={nodeRef}
                          className={`workspace-settings-page__content fade fade-${state}`}
                          style={{
                            ...defaultStyle,
                            // @ts-ignore
                            ...transitionStyles[state]
                          }}
                      >
                        {item.child && item?.child.map((childItem, i) => (
                            <div
                                className={`workspace-settings-page__childPage ${childItem.key === activeChildPage ? "active" : ""}`}
                                key={i}
                                onClick={() => handleOpenChildPage(childItem.key, childItem.parent)}>
                              {childItem.title}
                            </div>
                        ))}
                      </div>
                  )}
                </Transition>
              </div>
          ))}
        </div>

        <div className="workspace-settings-right">
        {activePage === 'users' && (
              <>
                {activeChildPage === 'users' && (
                    <WorkspaceUsersBlock />
                )}
              </>
          )}
        </div>
      </div>
    </div>
  )
}