import { useState, FC } from "react";
import { SliderBanners } from "./SliderBanners";
import HorizonNavigate from "../navigation/HorizonNavigate";
import { isMd } from "../../utils";

interface ISlider {
    isScrolled: boolean
}

export const Slider: FC<ISlider> = ({isScrolled}) => {
    const isMobile = isMd();

    const [ slidesContent, setSlidesContent ] = useState([
        {
            id: 1,
            image: '/testImages/banners/banner-4.png',
            title: 'Волейбольная команда Итэлма',
            text: 'Взяла бронзу в любительской лиге',
            layout: null,
            src: 'https://api.svoi.club/images/banners/u2Xo9oEejm.png',
            type: '0'
        },
        {
            id: 2,
            image: 'https://api.svoi.club/images/banners/78bxTAbAh9.png',
            title: 'Волейбольная команда Итэлма',
            text: 'Взяла бронзу в любительской лиге',
            layout: null,
            src: 'https://api.svoi.club/images/banners/78bxTAbAh9.png',
            type: '0'
        },
    ])

    return (
        <div id='content_id' className={ "main__home" }>
            <div className='slider'>
                <div className='slider-bannersContent'>
                    <SliderBanners slidersNumber={ slidesContent.length } slides={ slidesContent } />
                </div>

                { !isMobile && (
                    <div id='sliderRouters'>
                        <HorizonNavigate isScroll={isScrolled}/>
                    </div>
                ) }
            </div>
        </div>
    )
}