import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowExitIcon, EyeIcon, ShareIcon } from "../../../img";
import { $getSportById } from "../../../api/requests/sport";
import TrainingContentItem from "../TrainingContentItem";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData, SportViewItemData
} from "../../../api/requests/sport/interface";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import Button from "../../../components/ui/form/Buttons/Button";
import TrainingSessionViewModalSign from "./TrainingSessionViewModalSign";
import { isMd } from "../../../utils";

const daysOfWeek = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

const TrainingSessionViewPage = () => {
    const isMobile = isMd();

    const navigate = useNavigate();
    const { id } = useParams();

    const isDisabledSign = false;

    const [trainingSessionData, setTrainingSessionData] = useState<SportViewItemData | null>(null);
    const [isOpenModalSign, setIsOpenModalSign] = useState<boolean>(false);

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data.map((item) => {
            const dateObj = new Date(item.date);
            const weekDay = daysOfWeek[dateObj.getDay()];
            const formattedDate = `${String(dateObj.getDate()).padStart(2, "0")}.${String(dateObj.getMonth() + 1).padStart(2, "0")}`; // Format date as dd.mm
            const time = `${item.start} - ${item.end}`;

            return {
                week: weekDay,
                date: formattedDate,
                time: time
            };
        });
    };

    const transformContentData = (item: SportItemData): SportViewItemData => {
        return {
            ...item,
            date: transformDateData(item.date)
        };
    };

    useEffect(() => {
        if (id) {
            $getSportById(id).then(res => {
                if (!res.data) return;

                setTrainingSessionData(transformContentData(res.data));
            });
        }
    }, []);

    function handleOpenSign() {
        setIsOpenModalSign(true);
    }

    return (
        <div className="news-show">
            <div className="layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{trainingSessionData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="news-show-content">
                <div className="news-show-content__left">
                    <div className="sport__view">
                        <div className="sport__view__content">
                            {trainingSessionData &&
                              <TrainingContentItem {...trainingSessionData} />}

                            {trainingSessionData?.location && (
                                <div className="sport__view__content__location">
                                    <LocationIcon />

                                    <p>Стадион “Спартак”, г. Москва, Лененский проспект</p>
                                </div>
                            )}

                            {trainingSessionData?.users && (
                                <div className="sport__view__content__users">
                                    <p>Участники</p>

                                    <div className="layout-congratulations__content">
                                        {trainingSessionData?.users.slice(0, 10).map((item, idx) => (
                                            <div
                                                className={`layout-congratulations__block ${idx === 0 ? "first" : ""}`}
                                                key={idx}
                                            >
                                                <img src={item.image} alt="" />
                                            </div>
                                        ))}

                                        {trainingSessionData?.users?.length > 10 && (
                                            <div className={"layout-congratulations__number"}>{trainingSessionData?.users?.length - 10}</div>
                                        )}
                                    </div>
                                </div>
                            )}

                            <div className="sport__content-card-block__bottom">
                                <div className="news-show-info__buttons">
                                    <Button
                                        text={"Записаться на тренировку"}
                                        className="btn btn-primary news-show-info__buttons__likeButton"
                                        onClick={handleOpenSign}
                                        disabled={isDisabledSign}
                                    />

                                    <button className="news-show-info__buttons__shareButton">
                                        <ShareIcon size={24} color="#00A7B5" /> <p>Поделиться</p>
                                    </button>
                                </div>

                                <div className="news-show-info__stats">
                                    {trainingSessionData?.views && (
                                        <div className="news-show-info__stats__block">
                                            <EyeIcon color="#000" size={20} />

                                            <p>{trainingSessionData.views}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isDisabledSign && (
                                <div className={"sport__content-card-block__bottom error"}>
                                    <p>К сожалению, вы были временно заблокированы для записи на
                                        тренировки из-за трех пропущенных занятий. Пожалуйста,
                                        свяжитесь с администратором для разблокировки.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <TrainingSessionViewModalSign
                isOpen={isOpenModalSign}
                onClose={setIsOpenModalSign}
                data={trainingSessionData}
            />
        </div>
    );
};

export default TrainingSessionViewPage;