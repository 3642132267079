import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import Button from "../../../components/ui/form/Buttons/Button";
import { SportViewItemData } from "../../../api/requests/sport/interface";
import ToggleList from "../../../components/ui/form/Toggle/ToggleList";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

interface TrainingSessionViewModalSignProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    data?: SportViewItemData | null;
}

interface FormData {
    date: string;
    time: string;
}

interface dateItem {
    text: string;
    disabled: boolean;
}

const TrainingSessionViewModalSign = (
    {
        data,
        isOpen,
        onClose
    }: TrainingSessionViewModalSignProps
) => {

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid }
    } = useForm<FormData>();

    const trainingDateList = (): dateItem[] => {
        if (!data?.date) return [];

        return [...data?.date].map(item => ({
            text: item.date || "",
            disabled: false
        }));
    };

    const [trainingTimeList, setTrainingTimeList] = useState<dateItem[]>([]);

    useEffect(() => {
        if (data?.date) {
            const updateList = [...data?.date].map(item => ({
                text: item.time || "",
                disabled: true
            }));

            setTrainingTimeList(updateList);
        }
    }, [data]);

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(data: any) {
        setIsStatusComplete(true);
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleCloseModal}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && "Запись на тренировку"}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3>Поздравляем</h3>

                        <p>
                            Вы успешно записались.
                            Хорошей тренировки!
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form className={"sport__view__modal"} onSubmit={handleSubmit(onSubmit)}>
                    <div className="sport__view__modal__content">
                        <Controller
                            name="date"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleList
                                    title={"Дата тренировки"}
                                    list={trainingDateList()}
                                    onChange={(item) => {
                                        onChange(item.text);

                                        const updateList = [...trainingTimeList].map(i => ({
                                            ...i,
                                            disabled: false
                                        }));

                                        setTrainingTimeList(updateList);
                                    }}
                                    value={value}
                                />
                            )}
                        />

                        <Controller
                            name="time"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleList
                                    title={"Время тренировки"}
                                    list={trainingTimeList}
                                    onChange={(item) => onChange(item.text)}
                                    value={value}
                                />
                            )}
                        />

                        <div className="sport__content-card-user">
                            <img src={data?.user.image} alt=""
                                 className="sport__content-card-user__image" />

                            <div className="sport__content-card-user__content">
                                <h1 className="sport__content-card-user__content__name">{data?.user.name}</h1>
                                <p className="sport__content-card-user__content__position">{data?.user.position}</p>
                            </div>
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            type={"submit"}
                            text={"Записаться"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default TrainingSessionViewModalSign;