import { ProfileFileIcon, ProfileQrCodeSmallIcon } from "../../img/icons/layout-components";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import { getProfileData } from "../../redux";
import { useLocation, useNavigate } from "react-router-dom";

interface IUserData {
  data: any
}
export const UserProfileCard: FC<IUserData> = ({
  data
}) => {
  const profileData = useSelector(getProfileData)

  const navigate = useNavigate();
  const location = useLocation()
  const [ openPopup, setOpenPopup ] = useState(false);

  return (
    <div className='user-profile'>
      <div className='user-profile__avaBox'  /*  onClick={() => push('/profile-settings')} */
           style={ {cursor: 'pointer'} }>
        {data?.photo ? (
          <img src={ data?.photo } className='user-profile__avatar'
               style={ {marginRight: '0'} } />
        ) : (
          <div className='user-profile__avatar__noneImage'>
            {data?.name[0]}
          </div>
        )}
      </div>

      <div className='user-profile__nameBlock'>
        <h1>{data?.name}</h1>

        <div>
          <ProfileFileIcon />

          <p>{data?.position}</p>
        </div>
      </div>

      <div className='user-profile__buttons'>
        <div className='user-profile__buttons__item thanks'>Поблагодарить</div>

        <div className='user-profile__buttons__item writing'>Написать</div>
      </div>
    </div>
  )
}