import { FC, useState } from "react";
import { ArrowRightIcon } from "../../img";
import { ILinks, isMd } from "../../utils";

interface ILinksCard {
    data: ILinks[]
}
export const LinksCard: FC<ILinksCard> = ({
  data
}) => {
    const isMobile = isMd()
    // const [linksContent, setLinksContent] = useState<ILinksCard[]>([
    //     {
    //         id: 1,
    //         image: '/testImages/usefulLinks/links-1.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-1.png',
    //         title: 'О компании'
    //     },
    //     {
    //         id: 2,
    //         image: '/testImages/usefulLinks/links-2.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-2.png',
    //         title: 'Учебный портал'
    //     },
    //     {
    //         id: 3,
    //         image: '/testImages/usefulLinks/links-3.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-3.png',
    //         title: 'СЭД'
    //     },
    //     {
    //         id: 4,
    //         image: '/testImages/usefulLinks/links-4.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-4.png',
    //         title: 'Меню в столовой'
    //     },
    //     {
    //         id: 5,
    //         image: '/testImages/usefulLinks/links-5.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-5.png',
    //         title: 'Зарядка в офисе'
    //     },
    //     {
    //         id: 6,
    //         image: '/testImages/usefulLinks/links-6.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-6.png',
    //         title: '30 добрых дней'
    //     },
    //     {
    //         id: 7,
    //         image: '/testImages/usefulLinks/links-7.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-7.png',
    //         title: 'Стратегия развития'
    //     },
    //     {
    //         id: 8,
    //         image: '/testImages/usefulLinks/links-8.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-8.png',
    //         title: 'Движение вверх'
    //     },
    //     {
    //         id: 9,
    //         image: '/testImages/usefulLinks/links-9.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-9.png',
    //         title: 'Транспорт'
    //     },
    //     {
    //         id: 10,
    //         image: '/testImages/usefulLinks/links-10.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-10.png',
    //         title: 'Структура компании'
    //     },
    //     {
    //         id: 11,
    //         image: '/testImages/usefulLinks/links-11.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-11.png',
    //         title: 'Спорт в ВСК'
    //     },
    //     {
    //         id: 12,
    //         image: '/testImages/usefulLinks/links-12.png',
    //         sort: 0,
    //         src: '/testImages/usefulLinks/links-12.png',
    //         title: 'Сотрудники'
    //     },
    // ])

    return (
        <>
            {isMobile ? (
                <div className='layout-links-mobile'>
                    <div className='feed-controls feed-news__controls' >
                        <h3>Полезные ссылки</h3>
                    </div>

                    <div className='layout-links__content'>
                        {data.slice(0, 4).map((item, idx) => (
                            <div key={idx} className='layout-links__block'>
                                <div className='layout-links__block__image'>
                                    {item.image ? (
                                      <img src={item?.image} alt="" />
                                    ) : (
                                      <div className='layout-links__block__noneImage'>
                                          {item?.title[0]}
                                      </div>
                                    )}
                                </div>

                                <h1>{item.title}</h1>
                            </div>
                        ))}
                    </div>

                    <div className='layout-links-mobile__button'>
                        Показать все
                    </div>
                </div>
            ) : (
                <div className='layout-links'>
                    <div className='layout-links__header'>
                        <h1> Полезные ссылки</h1>

                        <div className='layout-links__header__more'>
                            Показать все
                        </div>
                    </div>

                    <div className='layout-links__content'>
                        {data.slice(0, 6).map((item, idx) => (
                            <div key={idx} className='layout-links__block'>
                                <div className='layout-links__block__image'>
                                    {item.image ? (
                                      <img src={item?.image} alt="" />
                                    ) : (
                                      <div className='layout-links__block__noneImage'>
                                          {item?.title[0]}
                                      </div>
                                    )}
                                </div>

                                <h1>{item.title}</h1>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>

    )
}